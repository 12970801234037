$(document).ready(function(){
      $('.slider-pr__slider').slick({
            rows: 2,
            slidesPerRow: 3,
            nextArrow: '.next-slide',
            prevArrow: '',
            responsive: [
              {
                breakpoint: 920,
                settings:{
                  slideToShow: 2,
                  slidesPerRow: 2
                }
              },
              {
                breakpoint: 700,
                settings:{
                  slideToShow: 1,
                  slidesPerRow: 1
                }
              }
            ]
          });
          ymaps.ready(function () {
            var myMap = new ymaps.Map('map', {
                    center: [55.910528071488656,37.72493420304105],
                    zoom: 15
                }, {
                    searchControlProvider: 'yandex#search'
                });
                myMap.geoObjects
                  .add(new ymaps.Placemark([55.910528071488656,37.72493420304105]));
                  
          });
          $('.toTop').click(function(e){
            e.preventDefault();
            $('body,html').animate({ scrollTop: 0 }, 1000);
          });
          $('.header__menu a, .header-call, .header__mobile-menu a').click(function(e){
            e.preventDefault();
            var id = $(this).attr('href');
            $('.header__mobile-menu').slideToggle();
            $('body,html').animate({ scrollTop: $(id).offset().top - 100}, 1000);
          });
          $('.header__mobile-burger').click(function(){
            $(this).toggleClass('active');
            $('.header__mobile-menu').slideToggle({
              start: function(){
                $(this).css('display','flex');
              }
            });
          });	
});